import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import theme from "../../theme";

interface Props {}

const FosterView: React.FC<Props> = (props) => {
  const fosterCards = [
    {
      id: 1,
      title: "What does fostering mean?",
      paragraphs: [
        "Fostering means bringing an unwanted or homeless dog into your home and caring for him/her until the dog is adopted.",
        "A foster home is a critical step in a dog's journey to find a forever home. It is a temporary living situation where the family provides shelter, food, care, love, and guidance. The number of dogs we can save depends entirely on the number of people who will open their homes and hearts to foster them. For many of these dogs, this is their first experience being treated as a family pet. You are their salvation, and you should never underestimate the important role you are playing in their lives!",
      ],
    },
    {
      id: 2,
      title: "Why foster?",
      paragraphs: [
        "By fostering you are saving the lives of unwanted or homeless dogs. HAL does not have a physical facility where our dogs can be housed; we rely solely on foster homes to provide all the care a homeless dog needs.",
        "Many of our dogs have endured difficult pasts such as abuse, cruelty, neglect, lack of socialization, etc., and as a foster home, you provide an environment for the dog to feel safe. A primary goal is to show a dog what it means to be loved which most of our dogs have never experienced before coming to HAL. During the process of gaining a dog's trust and helping him/her learn how to be a valuable family member, you will see the dog transform before your eyes.",
        "In addition to providing necessary care and lots of love you can work with a dog on leash, house, crate and/or other basic command training which most adopters appreciate. You don't have to be an expert; every little bit helps. We have a certified dog trainer and experienced volunteers who can provide guidance & support.",
        "While fostering you will be able to assess the dog's personality, temperament, energy level, training needs and the like to help match the dog with a home that will be a good fit for both the family & our HAL dog.",
      ],
    },
    {
      id: 3,
      title: "What's in it for me?",
      paragraphs: [
        "Being a foster family has rewards beyond the essential value of helping an unwanted dog find its forever home. For some people it is an opportunity to have a canine companion without making a lifetime commitment. If you have existing pets, a foster dog can be a playmate/companion and provide opportunities for socialization. Children can benefit from fostering too. It's a way to introduce responsibility & selflessness, as well as provide a hands-on opportunity to educate them about animals. Fostering can also be a great way to 'test' the idea of permanently adding a dog to your family.",
        "You become part of a wonderful “community”. Fellow HAL fosters and volunteers will help guide you through the ins and outs of fostering, share their stories, answer any questions, and you may even develop friendships! There is nothing quite like the meaningful experiences you'll have with a diverse community of dog-loving people.",
        "You will make someone's family complete. When the day comes that your foster finds his/her forever home, you will feel a sense of pride knowing all your efforts to get that dog ready to be a family pet have paid off. You'll be overjoyed that he/she has found their forever family. And while saying goodbye can be tough, you know your foster has found a well-deserved happy ending and recognize that there's another precious life waiting for your help. ",
      ],
    },
    {
      id: 4,
      title: "How long can I expect to foster a dog?",
      paragraphs: [
        "HAL has two (2) types of fostering opportunities. The first, which we call a “perm foster”, keeps a dog until it's adopted. The timeline can vary greatly, but usually is a minimum of 1 week and, on average, lasts for 2-4 weeks. However, some dogs need more time because of age, behavior issues, and/or medical condition, or just because their forever family hasn't found them yet. You always get to choose the dogs you foster. HAL will share the info we have about a dog before it arrives in your home. Surprises do happen, though, so being willing to deal with whatever is thrown at you is a real plus; you will always have the HAL support system to help.",
        "If you don't want to commit to an unknown end date, we need “temp fosters”. These people keep a dog for a defined period - from a few hours to a week or more - depending on how long the perm foster needs coverage. You pick the temp jobs that work for you. ",
      ],
    },
    {
      id: 5,
      title: "What are some of the responsibilities of a foster family?",
      list: [
        "Provide a safe, clean, and caring environment.",
        "Provide shelter, food, water, exercise, and socialization.",
        "Get to know the dog - its personality, temperament, needs and the like which helps find a good fit forever home.",
        "Evaluate / monitor medical and/or behavioral needs (HAL partners w/ vets and a certified trainer to help address).",
        "Attend Meet & Greet events - attendance is optional but encouraged as these events increase a dog's exposure to potential adopters.",
        "Participate in finding the dog's forever home. This could include writing the dog's bio, taking pictures/ videos, communicating w/ potential adopters, contacting references, and conducting a home visit.",
      ],
    },
  ];

  const fosterLetter = [
    {
      sentences: [
        "There I sat, alone and afraid,",
        "You got a call and came right to my aid.",
        "You bundled me up with blankets and love,",
        "And, when I needed it most, you gave me a hug.",
      ],
    },
    {
      sentences: [
        "I learned that the world was not all that scary and cold,",
        "That sometimes there is someone to have and to hold.",
        "You taught me what love is, you helped me to mend,",
        "You loved me and healed me and became my first friend.",
      ],
    },
    {
      sentences: [
        "And just when I thought you'd done all you do,",
        "There came along not one new lesson, but two.",
        "First you said, 'Sweetheart, you're ready to go,",
        "I've done all I can, and you've learned all I know.'",
        "Then you bundled me up with a blanket and kiss,",
        "Along came a new family, they even have kids!",
      ],
    },
    {
      sentences: [
        "They took me to their home, forever to stay,",
        "At first I thought you sent me away.",
        "Then that second lesson became perfectly clear,",
        "No matter how far, you will always be near.",
      ],
    },
    {
      sentences: [
        "And so, Foster Parent, you know I've moved on,",
        "I have a new home, with toys and a lawn.",
        "But I'll never forget what I learned that first day,",
        "You never really give your fosters away.",
      ],
    },
    {
      sentences: [
        "You gave me these thoughts to remember you by,",
        "We may never meet again, and now I know why.",
        "You'll remember I lived with you for a time,",
        "I may not be yours, but you'll always be mine.",
      ],
    },
  ];

  return (
    <Grid container pb={5} direction={"row"} justifyContent={"center"}>
      <Grid item xs={12} mb={2}>
        <Typography textAlign={"center"} variant="h1">
          FOSTERING is a very rewarding experience!
        </Typography>
      </Grid>
      <Grid xs={12} item display="flex" justifyContent="center" alignSelf={"center"} mb={3}>
        <Box
          component="img"
          alt={`dog on a leash`}
          src="/static/images/foster.jpeg"
          sx={{
            objectFit: "cover",
            width: { md: "500px", xs: "350px" },
            height: { md: "300px", xs: "200px" },
            borderRadius: "25px",
          }}
        />
      </Grid>
      <Grid item xs={12} sx={{ paddingX: { lg: 10, md: 5, xs: 5 } }} mb={2}>
        <Typography variant="body1" textAlign={"center"}>
          We are not currently accepting foster applications at this time. Please check back later!
        </Typography>

        {/* <Typography variant="body1" textAlign={"center"}>
          The ins and outs of fostering is explained below, however, if you already know you want to get the approval
          process started, please{" "}
          <Box
            component={"span"}
            sx={{ textDecoration: "underline", fontWeight: "bold", cursor: "pointer" }}
            onClick={() => window.open("https://form.jotform.com/homeatlastdogrescue/volunteerapp")}
          >
            submit an application!
          </Box>
        </Typography> */}
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent={"center"} alignItems={"center"}>
          {fosterCards.map((card) => (
            <Grid md={8} xs={12} key={card.id} item sx={{ px: { md: 10, sm: 5, xs: 3 } }} mx={{ md: 3, xs: 0 }} mb={5}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    padding: 1,
                    borderRadius: 2,
                    marginBottom: "-25px",
                    zIndex: 100,
                    alignSelf: "center",
                  }}
                >
                  <Typography color={theme.palette.primary.contrastText} variant="h2" textAlign={"center"}>
                    {card.title}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    backgroundColor: theme.palette.background.paper,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingX: { md: 10, xs: 3 },
                    borderRadius: 5,
                    paddingY: 3,
                  }}
                >
                  {card.paragraphs && (
                    <>
                      {card.paragraphs.map((li, index) => (
                        <Grid
                          key={index}
                          container
                          direction={"row"}
                          justifyContent={"flex-start"}
                          alignItems={"center"}
                          my={1}
                        >
                          <Grid item>
                            <Typography textAlign={{ xs: "center", sm: "left" }}>{li}</Typography>
                          </Grid>
                        </Grid>
                      ))}
                    </>
                  )}
                  {card.list && (
                    <ul>
                      {card.list.map((li, index) => (
                        <li key={index}>
                          <Grid container direction={"row"} justifyContent={"flex-start"} alignItems={"center"} my={1}>
                            <Grid item>
                              <Typography>{li}</Typography>
                            </Grid>
                          </Grid>
                        </li>
                      ))}
                    </ul>
                  )}
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item mx={2} mb={3} xs={12}>
        <Typography variant="body1" textAlign={"center"}>
          We are not currently accepting foster applications at this time. Please check back later!
        </Typography>
        {/* <Typography textAlign={"center"} variant="h2" mb={1}>
          READY TO BRING A FOSTER DOG INTO YOUR HOME???
        </Typography>
        <Typography textAlign={"center"} variant="body1">
          To get the approval process started: please{" "}
          <Box
            component={"span"}
            sx={{ textDecoration: "underline", fontWeight: "bold", cursor: "pointer" }}
            onClick={() => window.open("https://form.jotform.com/homeatlastdogrescue/volunteerapp")}
          >
            submit an application!
          </Box>
        </Typography> */}
      </Grid>
      <Grid
        item
        md={7}
        xs={12}
        sx={{
          backgroundColor: theme.palette.primary.main,
          paddingY: 3,
          borderRadius: 5,
          alignSelf: "center",
        }}
      >
        <Typography variant="h1" color={theme.palette.primary.contrastText} mb={3} textAlign={"center"}>
          SO SAVE A LIFE, CHANGE A LIFE, BECOME A FOSTER TODAY!
        </Typography>
        <Typography variant="h2" color={theme.palette.primary.contrastText} fontStyle={"italic"} textAlign={"center"}>
          Dear Foster Parent,
        </Typography>
        <Grid container direction={"column"}>
          {fosterLetter.map((paragraph, index) => (
            <Grid item my={2} key={index}>
              {paragraph.sentences.map((sentence, index) => (
                <Typography
                  color={theme.palette.primary.contrastText}
                  fontStyle={"italic"}
                  textAlign={"center"}
                  key={index}
                >
                  {sentence}
                </Typography>
              ))}
            </Grid>
          ))}
        </Grid>
        <Typography color={theme.palette.primary.contrastText} fontStyle={"italic"} textAlign={"center"}>
          With love & gratitude,
        </Typography>
        <Typography color={theme.palette.primary.contrastText} fontStyle={"italic"} textAlign={"center"}>
          Your foster dog
        </Typography>
      </Grid>
    </Grid>
  );
};

export default FosterView;
