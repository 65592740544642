import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { Inputs } from "../forms";
import * as Yup from "yup";
import ActionButton from "../base/ActionButton";
import theme from "../../theme";

interface Props {}

const AboutUsView: React.FC<Props> = (props) => {
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("You must include your first name").typeError("Required"),
    lastName: Yup.string().required("You must include your last name").typeError("Required"),
    message: Yup.string().required("You must include a message").typeError("Required"),
  });

  return (
    <Grid container direction={"row"} rowSpacing={5}>
      <Grid item xs={12}>
        <Box sx={{ marginTop: 2, marginBottom: { md: 5, sm: 0 } }}>
          <Typography textAlign={"center"} variant="h1">
            About Us
          </Typography>
        </Box>
      </Grid>

      {/* Left Side */}
      <Grid item md={7} sm={12} sx={{ paddingX: { lg: 10, md: 5, xs: 5 } }}>
        <Grid container direction={"column"} spacing={2}>
          <Grid item>
            <Typography variant="body1" sx={{ mb: 3 }}>
              <strong>Home At Last Dog Rescue (HAL)</strong> was founded in North Wales PA, in 2008 as an all age, all
              breed, dog rescue. HAL is a registered 501(c)(3) non-profit organization.
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
              We are a group of dog-loving volunteers whose goal is to save the lives of homeless dogs suffering in
              high-kill shelters and puppy mills, as well as dogs in other unfortunate circumstances.
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
              We are not a shelter; thus, we have no central location or physical facility to house the dogs we rescue.
              It is through volunteer foster homes, mostly located in the Greater Philadelphia, PA Region, that we can
              provide homes for the dogs in our care. Our dogs remain in foster homes until they are matched and adopted
              into their forever families.
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              In doing this, we hope to provide an alternative to the purchase of breeder and pet store dogs as well as
              prevent unwanted dogs from entering the overwhelmed shelter system. Since 2008 we have rescued over 20,000
              dogs.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="button">Email</Typography>
            <Typography variant="body1">info@homeatlastdogrescue.com</Typography>
          </Grid>
          <Grid item>
            <Typography variant="button">Mailing Address:</Typography>
            <Typography variant="body1">Home At Last Dog Rescue</Typography>
            <Typography variant="body1">P.O. Box 1341</Typography>
            <Typography variant="body1">North Wales, PA 19454</Typography>
          </Grid>
        </Grid>
      </Grid>
      {/* Right Side */}
      <Grid item md={5} sm={12} sx={{ paddingX: { lg: 10, md: 7, xs: 5 } }}>
        <Grid container direction={"column"}>
          <Grid item sx={{ mb: 2 }}>
            <Box
              component="img"
              alt={`dogs sitting on a bench`}
              src="/static/images/aboutus.jpeg"
              sx={{
                objectFit: "cover",
                width: "100%",
                borderRadius: "25px",
              }}
            />
          </Grid>
          {/* <Grid item sx={{ mb: 4, display: "flex", justifyContent: "center" }}>
            <Typography justifyContent="center" variant="h2">
              Interested in Volunteering? Click{" "}
              <a
                href="mailto:HomeAtLastVolunteerInfo@gmail.com?subject=Interested in Volunteering"
                style={{ color: theme.palette.primary.main }}
              >
                here!
              </a>
            </Typography>
          </Grid> */}
          <Grid item>
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                message: "",
              }}
              validationSchema={validationSchema}
              onSubmit={() => {}}
            >
              {({ values }) => (
                <Form>
                  <Grid container direction={"column"}>
                    <Grid container>
                      <Grid item xs={12} sx={{ p: "10px", paddingBottom: "25px" }}>
                        <Typography variant="h2">Contact Us</Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Field component={Inputs.Text} name="firstName" label="First Name" secondary />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Field component={Inputs.Text} name="lastName" label="Last Name" secondary />
                      </Grid>
                    </Grid>
                    <Grid container direction={"row"}>
                      <Grid item xs={12}>
                        <Field component={Inputs.TextArea} name="message" label="Message" secondary />
                      </Grid>
                    </Grid>
                    <Grid item py={2} alignSelf={"flex-end"}>
                      {!values.firstName || !values.lastName || !values.message ? (
                        <ActionButton text="Compose Email" type="button" disabled={true} onClick={() => {}} />
                      ) : (
                        <a
                          style={{ textDecoration: "none" }}
                          href={`mailto:info@homeatlastdogrescue.com?subject=A message from ${values.firstName} ${values.lastName}&body=${values.message}`}
                        >
                          <ActionButton text="Compose Email" type="button" onClick={() => {}} />
                        </a>
                      )}
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AboutUsView;
