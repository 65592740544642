import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "../../store";
import {
  Box,
  Grid,
  DialogActions,
  Typography,
  CircularProgress,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
} from "@mui/material";
import StyledTable from "../base/StyledTable";
import TableActions from "../base/TableActions";
import Search from "../base/Search";
import { Container } from "@mui/system";
import ActionButton from "../base/ActionButton";
import { getUserSearch } from "../../slices/users";
import { debounce } from "lodash";
import KeyValue from "../../types/keyValue";
import { useNavigate } from "react-router-dom";
import { getStatusOptions, getSubStatus } from "../../slices/options";
import { Field, Form, Formik } from "formik";
import { Inputs } from "../forms";
import { deleteDog, getDogById, getDogSimpleById, getDogsSearch, resetDog, setDog } from "../../slices/dogs";
import DogSimple from "../../types/dogSimple";
import Moment from "react-moment";
import Dialog from "../base/Dialog";
import checkUserRole, { UserRoles } from "../../helpers/checkUserRole";
import {
  resetDogFoster,
  resetDogStatus,
  resetDogFilter,
  resetDogIntakeEnd,
  resetDogIntakeStart,
  resetDogStatusEnd,
  resetDogStatusStart,
  resetSubStatus,
  setDogFoster,
  setDogStatus,
  setDogFilter,
  setDogIntakeEnd,
  setDogIntakeStart,
  setDogStatusEnd,
  setDogStatusStart,
  setResetDogFilterBool,
  setSubStatus,
} from "../../slices/filters";
import { combineDocuments } from "../../slices/file";
import CheckboxButton from "../base/CheckboxButton";
import SearchResults from "../../types/searchResults";

interface Props {}

const AdminDogTable: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loggedInUser } = useSelector((store) => store.auth);
  const { statuses, subStatus } = useSelector((store) => store.options);
  const { dogSearch, dog, searchStatus, status } = useSelector((store) => store.dogs);
  const { downloadStatus } = useSelector((store) => store.file);
  const {
    dogFilter,
    dogIntakeStart,
    dogIntakeEnd,
    dogStatusStart,
    dogStatusEnd,
    resetDogFilterBool,
    dogFoster,
    dogStatus,
    dogSubStatus,
  } = useSelector((store) => store.filters);

  let date = new Date();

  const initialCheckedState = dogSearch?.results
    ? dogSearch?.results?.reduce((acc, file) => {
        acc[file.id!] = false;
        return acc;
      }, {} as { [key: number]: boolean })
    : ({} as { [key: number]: boolean });

  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [searchValue, setSearchValue] = useState(dogFilter.query);
  const [subStatusFilters, setSubStatusFilters] = useState<string[]>();
  const [checked, setChecked] = useState(initialCheckedState);
  const [selectAll, setSelectAll] = useState(false);
  const [selecting, setSelecting] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [fileList, setFileList] = useState<number[]>([]);

  useEffect(() => {
    checkForReset();
  }, [dispatch]);

  useEffect(() => {
    dispatch(getStatusOptions({ query: "", pageNumber: 1, pageSize: 10 }));
    dispatch(getUserSearch({ query: "", pageNumber: 1, pageSize: 10, filters: ["role:3"] }));
  }, [dispatch]);

  //reset dates if it's cleared and set to december 31, 1969
  useEffect(() => {
    if (dogIntakeStart?.toDateString() === "Wed Dec 31 1969") {
      dispatch(resetDogIntakeStart());
    }
    if (dogIntakeEnd?.toDateString() === "Wed Dec 31 1969") {
      dispatch(resetDogIntakeEnd());
    }
    if (dogStatusStart?.toDateString() === "Wed Dec 31 1969") {
      dispatch(resetDogStatusStart());
    }
    if (dogStatusEnd?.toDateString() === "Wed Dec 31 1969") {
      dispatch(resetDogStatusEnd());
    }
  }, [dogIntakeStart, dogIntakeEnd, dogStatusStart, dogStatusEnd]);

  // Search Bar
  const updateSearch = debounce((query: string) => {
    setSearchValue(query);
    dispatch(setDogFilter({ ...dogFilter, pageNumber: 1, pageSize: 10, query: query }));
    dispatch(
      getDogsSearch({
        search: { ...dogFilter, pageNumber: 1, pageSize: 10, query: query },
        intakeStart: dogIntakeStart!,
        intakeEnd: dogIntakeEnd!,
        statusStart: dogStatusStart!,
        statusEnd: dogStatusEnd!,
      })
    );
  }, 250);

  // Delete
  const handleDeleteOpen = (id: number) => {
    setDeleteModal(true);
    dispatch(getDogById(id));
    setDeleteId(id);
  };

  const handleDeleteDog = async (id: number) => {
    await dispatch(deleteDog(id));
  };

  const cancelDelete = () => {
    setDeleteModal(false);
    setDeleteId(0);
  };

  // Download and Print
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked: isChecked } = event.currentTarget;

    if (id === "selectAll") {
      setSelectAll(isChecked);
      setSelecting(true);
      dispatch(
        getDogsSearch({
          search: { ...dogFilter, pageNumber: 1, pageSize: dogSearch?.totalResults },
          intakeStart: dogIntakeStart!,
          intakeEnd: dogIntakeEnd!,
          statusStart: dogStatusStart!,
          statusEnd: dogStatusEnd!,
        })
      ).then((result) => {
        if (result && result.payload) {
          let searches = result.payload as SearchResults;
          setChecked((prevChecked: { [key: number]: boolean }) => {
            const updatedState: { [key: number]: boolean } = {};
            searches?.results?.forEach((dog) => {
              updatedState[dog.id!] = isChecked;
            });
            setSelecting(false);
            return { ...prevChecked, ...updatedState };
          });
        }
      });
    } else {
      setChecked((prevChecked: { [key: number]: boolean }) => ({
        ...prevChecked,
        [parseInt(id)]: isChecked,
      }));
    }
  };

  const downloadAllDocuments = () => {
    dispatch(
      combineDocuments({
        ...dogFilter,
        pageNumber: 1,
        pageSize: dogSearch?.totalResults,
        query: searchValue,
        intakeStart: dogIntakeStart!,
        intakeEnd: dogIntakeEnd!,
        statusStart: dogStatusStart!,
        statusEnd: dogStatusEnd!,
      })
    );
  };

  const printSelectedDocuments = () => {
    let idFilters: string[] = [];

    for (const key in checked) {
      if (checked.hasOwnProperty(key)) {
        const value = checked[key];
        if (!!value) {
          idFilters.push(`dog-id:${key}`);
        }
      }
    }

    console.log("dog ids", idFilters.length);

    dispatch(
      combineDocuments({
        ...dogFilter,
        pageSize: dogSearch?.totalResults,
        filters: idFilters,
      })
    );
  };

  // Sorting
  const [idOrderDirection, setIdOrderDirection] = useState(2);
  const [nameOrderDirection, setNameOrderDirection] = useState(1);
  const [akaOrderDirection, setAkaOrderDirection] = useState(2);
  const [breedOrderDirection, setBreedOrderDirection] = useState(2);
  const [fosterOrderDirection, setFosterOrderDirection] = useState(2);
  const [statusOrderDirection, setStatusOrderDirection] = useState(2);
  const [intakeOrderDirection, setIntakeOrderDirection] = useState(2);
  const [statusChangeOrderDirection, setStatusChangeOrderDirection] = useState(2);

  const handleSort = (e: any) => {
    if (e.target.id.includes("ID")) {
      dispatch(
        getDogsSearch({
          search: {
            ...dogFilter,
            orderBy: "id",
            orderDirection: idOrderDirection === 1 ? "Descending" : "Ascending",
          },
          intakeStart: dogIntakeStart!,
          intakeEnd: dogIntakeEnd!,
          statusStart: dogStatusStart!,
          statusEnd: dogStatusEnd!,
        })
      ).then(() => {
        if (idOrderDirection === 1) {
          setIdOrderDirection(2);
          setNameOrderDirection(1);
          setAkaOrderDirection(1);
          setBreedOrderDirection(1);
          setFosterOrderDirection(1);
          setStatusChangeOrderDirection(1);
          setStatusOrderDirection(1);
          setIntakeOrderDirection(1);
          dispatch(setDogFilter({ ...dogFilter, orderBy: "id", orderDirection: "Descending" }));
        } else if (idOrderDirection === 2) {
          setIdOrderDirection(1);
          setNameOrderDirection(2);
          setAkaOrderDirection(2);
          setBreedOrderDirection(2);
          setFosterOrderDirection(2);
          setStatusChangeOrderDirection(2);
          setStatusOrderDirection(2);
          setIntakeOrderDirection(2);
          dispatch(setDogFilter({ ...dogFilter, orderBy: "id", orderDirection: "Ascending" }));
        }
      });
    } else if (e.target.id.includes("Name")) {
      dispatch(
        getDogsSearch({
          search: {
            ...dogFilter,
            orderBy: "name",
            orderDirection: nameOrderDirection === 1 ? "Descending" : "Ascending",
          },

          intakeStart: dogIntakeStart!,
          intakeEnd: dogIntakeEnd!,
          statusStart: dogStatusStart!,
          statusEnd: dogStatusEnd!,
        })
      ).then(() => {
        if (nameOrderDirection === 1) {
          setIdOrderDirection(1);
          setNameOrderDirection(2);
          setAkaOrderDirection(1);
          setBreedOrderDirection(1);
          setFosterOrderDirection(1);
          setStatusChangeOrderDirection(1);
          setStatusOrderDirection(1);
          setIntakeOrderDirection(1);
          dispatch(setDogFilter({ ...dogFilter, orderBy: "name", orderDirection: "Descending" }));
        } else if (nameOrderDirection === 2) {
          setIdOrderDirection(2);
          setNameOrderDirection(1);
          setAkaOrderDirection(2);
          setBreedOrderDirection(2);
          setFosterOrderDirection(2);
          setStatusChangeOrderDirection(2);
          setStatusOrderDirection(2);
          setIntakeOrderDirection(2);
          dispatch(setDogFilter({ ...dogFilter, orderBy: "name", orderDirection: "Ascending" }));
        }
      });
    } else if (e.target.id.includes("AKA")) {
      dispatch(
        getDogsSearch({
          search: {
            ...dogFilter,
            orderBy: "aka",
            orderDirection: akaOrderDirection === 1 ? "Descending" : "Ascending",
          },

          intakeStart: dogIntakeStart!,
          intakeEnd: dogIntakeEnd!,
          statusStart: dogStatusStart!,
          statusEnd: dogStatusEnd!,
        })
      ).then(() => {
        if (akaOrderDirection === 1) {
          setIdOrderDirection(1);
          setNameOrderDirection(1);
          setAkaOrderDirection(2);
          setBreedOrderDirection(1);
          setFosterOrderDirection(1);
          setStatusChangeOrderDirection(1);
          setStatusOrderDirection(1);
          setIntakeOrderDirection(1);
          dispatch(setDogFilter({ ...dogFilter, orderBy: "aka", orderDirection: "Descending" }));
        } else if (akaOrderDirection === 2) {
          setIdOrderDirection(2);
          setNameOrderDirection(2);
          setAkaOrderDirection(1);
          setBreedOrderDirection(2);
          setFosterOrderDirection(2);
          setStatusChangeOrderDirection(2);
          setStatusOrderDirection(2);
          setIntakeOrderDirection(2);
          dispatch(setDogFilter({ ...dogFilter, orderBy: "aka", orderDirection: "Ascending" }));
        }
      });
    } else if (e.target.id.includes("Breed")) {
      dispatch(
        getDogsSearch({
          search: {
            ...dogFilter,
            orderBy: "breed",
            orderDirection: breedOrderDirection === 1 ? "Descending" : "Ascending",
          },

          intakeStart: dogIntakeStart!,
          intakeEnd: dogIntakeEnd!,
          statusStart: dogStatusStart!,
          statusEnd: dogStatusEnd!,
        })
      ).then(() => {
        if (breedOrderDirection === 1) {
          setIdOrderDirection(1);
          setNameOrderDirection(1);
          setAkaOrderDirection(1);
          setBreedOrderDirection(2);
          setFosterOrderDirection(1);
          setStatusChangeOrderDirection(1);
          setStatusOrderDirection(1);
          setIntakeOrderDirection(1);
          dispatch(setDogFilter({ ...dogFilter, orderBy: "breed", orderDirection: "Descending" }));
        } else if (breedOrderDirection === 2) {
          setIdOrderDirection(2);
          setNameOrderDirection(2);
          setAkaOrderDirection(2);
          setBreedOrderDirection(1);
          setFosterOrderDirection(2);
          setStatusChangeOrderDirection(2);
          setStatusOrderDirection(2);
          setIntakeOrderDirection(2);
          dispatch(setDogFilter({ ...dogFilter, orderBy: "breed", orderDirection: "Ascending" }));
        }
      });
    } else if (e.target.id.includes("Foster")) {
      dispatch(
        getDogsSearch({
          search: {
            ...dogFilter,
            orderBy: "foster",
            orderDirection: fosterOrderDirection === 1 ? "Descending" : "Ascending",
          },

          intakeStart: dogIntakeStart!,
          intakeEnd: dogIntakeEnd!,
          statusStart: dogStatusStart!,
          statusEnd: dogStatusEnd!,
        })
      ).then(() => {
        if (fosterOrderDirection === 1) {
          setIdOrderDirection(1);
          setNameOrderDirection(1);
          setAkaOrderDirection(1);
          setBreedOrderDirection(1);
          setFosterOrderDirection(2);
          setStatusChangeOrderDirection(1);
          setStatusOrderDirection(1);
          setIntakeOrderDirection(1);
          dispatch(setDogFilter({ ...dogFilter, orderBy: "foster", orderDirection: "Descending" }));
        } else if (fosterOrderDirection === 2) {
          setIdOrderDirection(2);
          setNameOrderDirection(2);
          setAkaOrderDirection(2);
          setBreedOrderDirection(2);
          setFosterOrderDirection(1);
          setStatusChangeOrderDirection(2);
          setStatusOrderDirection(2);
          setIntakeOrderDirection(2);
          dispatch(setDogFilter({ ...dogFilter, orderBy: "foster", orderDirection: "Ascending" }));
        }
      });
    } else if (e.target.id.includes("Changed")) {
      dispatch(
        getDogsSearch({
          search: {
            ...dogFilter,
            orderBy: "statusChanged",
            orderDirection: statusChangeOrderDirection === 1 ? "Descending" : "Ascending",
          },

          intakeStart: dogIntakeStart!,
          intakeEnd: dogIntakeEnd!,
          statusStart: dogStatusStart!,
          statusEnd: dogStatusEnd!,
        })
      ).then(() => {
        if (statusChangeOrderDirection === 1) {
          setIdOrderDirection(1);
          setNameOrderDirection(1);
          setAkaOrderDirection(1);
          setBreedOrderDirection(1);
          setFosterOrderDirection(1);
          setStatusChangeOrderDirection(2);
          setStatusOrderDirection(1);
          setIntakeOrderDirection(1);
          dispatch(setDogFilter({ ...dogFilter, orderBy: "statusChanged", orderDirection: "Descending" }));
        } else if (statusChangeOrderDirection === 2) {
          setIdOrderDirection(2);
          setNameOrderDirection(2);
          setAkaOrderDirection(2);
          setBreedOrderDirection(2);
          setFosterOrderDirection(2);
          setStatusChangeOrderDirection(1);
          setStatusOrderDirection(2);
          setIntakeOrderDirection(2);
          dispatch(setDogFilter({ ...dogFilter, orderBy: "statusChanged", orderDirection: "Ascending" }));
        }
      });
    } else if (e.target.id.includes("Status")) {
      dispatch(
        getDogsSearch({
          search: {
            ...dogFilter,
            orderBy: "status",
            orderDirection: statusOrderDirection === 1 ? "Descending" : "Ascending",
          },

          intakeStart: dogIntakeStart!,
          intakeEnd: dogIntakeEnd!,
          statusStart: dogStatusStart!,
          statusEnd: dogStatusEnd!,
        })
      ).then(() => {
        if (statusOrderDirection === 1) {
          setIdOrderDirection(1);
          setNameOrderDirection(1);
          setAkaOrderDirection(1);
          setBreedOrderDirection(1);
          setFosterOrderDirection(1);
          setStatusChangeOrderDirection(1);
          setStatusOrderDirection(2);
          setIntakeOrderDirection(1);
          dispatch(setDogFilter({ ...dogFilter, orderBy: "status", orderDirection: "Descending" }));
        } else if (statusOrderDirection === 2) {
          setIdOrderDirection(2);
          setNameOrderDirection(2);
          setAkaOrderDirection(2);
          setBreedOrderDirection(2);
          setFosterOrderDirection(2);
          setStatusChangeOrderDirection(2);
          setStatusOrderDirection(1);
          setIntakeOrderDirection(2);
          dispatch(setDogFilter({ ...dogFilter, orderBy: "status", orderDirection: "Ascending" }));
        }
      });
    } else if (e.target.id.includes("Intake")) {
      dispatch(
        getDogsSearch({
          search: {
            ...dogFilter,
            orderBy: "intake",
            orderDirection: intakeOrderDirection === 1 ? "Descending" : "Ascending",
          },

          intakeStart: dogIntakeStart!,
          intakeEnd: dogIntakeEnd!,
          statusStart: dogStatusStart!,
          statusEnd: dogStatusEnd!,
        })
      ).then(() => {
        if (intakeOrderDirection === 1) {
          setIdOrderDirection(1);
          setNameOrderDirection(1);
          setAkaOrderDirection(1);
          setBreedOrderDirection(1);
          setFosterOrderDirection(1);
          setStatusChangeOrderDirection(1);
          setStatusOrderDirection(1);
          setIntakeOrderDirection(2);
          dispatch(setDogFilter({ ...dogFilter, orderBy: "intake", orderDirection: "Descending" }));
        } else if (intakeOrderDirection === 2) {
          setIdOrderDirection(2);
          setNameOrderDirection(2);
          setAkaOrderDirection(2);
          setBreedOrderDirection(2);
          setFosterOrderDirection(2);
          setStatusChangeOrderDirection(2);
          setStatusOrderDirection(2);
          setIntakeOrderDirection(1);
          dispatch(setDogFilter({ ...dogFilter, orderBy: "intake", orderDirection: "Ascending" }));
        }
      });
    }
  };

  // Filtering
  const handleFiltering = (e: KeyValue[], filterName: string) => {
    let filters = dogFilter?.filters;
    let otherFilters = filters?.filter((f) => f.split(":")[0] !== filterName);

    if (!!e && !!e.length) {
      let newFilters: string[] = [];

      e.forEach((filter: KeyValue) => {
        let newFilterName = `${filterName}:${filter.id}`;
        newFilters.push(newFilterName);
      });

      let newList = otherFilters ? newFilters.concat(otherFilters) : newFilters;

      dispatch(setDogFilter({ ...dogFilter, pageNumber: 1, pageSize: 10, filters: newList }));
      dispatch(
        getDogsSearch({
          search: { ...dogFilter, pageNumber: 1, pageSize: 10, filters: newList },
          intakeStart: dogIntakeStart!,
          intakeEnd: dogIntakeEnd!,
          statusStart: dogStatusStart!,
          statusEnd: dogStatusEnd!,
        })
      );
      if (filterName === "status") {
        setSubStatusFilters(newFilters);
        dispatch(getSubStatus({ filters: newList }));
      }
    } else {
      dispatch(setDogFilter({ ...dogFilter, pageNumber: 1, pageSize: 10, filters: otherFilters }));
      dispatch(
        getDogsSearch({
          search: { ...dogFilter, pageNumber: 1, pageSize: 10, filters: otherFilters },
          intakeStart: dogIntakeStart!,
          intakeEnd: dogIntakeEnd!,
          statusStart: dogStatusStart!,
          statusEnd: dogStatusEnd!,
        })
      );
    }
  };

  const handleStatusFilter = (value: KeyValue[]) => {
    handleFiltering(value, "status");
    dispatch(setDogStatus(value));
  };

  const handleSubSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      getSubStatus({
        filters: subStatusFilters,
        query: e.currentTarget.value,
      })
    );
  };

  const handleSubStatusFilter = (
    event: React.SyntheticEvent,
    value: any | Array<any>,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any>
  ) => {
    handleFiltering(value, "sub");
    dispatch(setSubStatus(value));
  };

  const handleFosterFilter = (value: KeyValue[]) => {
    handleFiltering(value, "foster");
    dispatch(setDogFoster(value));
  };

  const handleClear = async () => {
    setSearchValue("");
    dispatch(resetDogFilter());
    dispatch(resetDogIntakeStart());
    dispatch(resetDogIntakeEnd());
    dispatch(resetDogStatusStart());
    dispatch(resetDogStatusEnd());
    dispatch(resetDogFoster());
    dispatch(resetDogStatus());
    dispatch(resetSubStatus());
    await dispatch(
      getDogsSearch({
        search: {
          pageNumber: 1,
          pageSize: 10,
          orderBy: "name",
          orderDirection: "Ascending",
          query: "",
        },
      })
    );
  };

  const checkForReset = () => {
    if (!resetDogFilterBool) {
      dispatch(setResetDogFilterBool(true));
    } else {
      handleClear();
    }
  };

  const handleReset = (resetForm: () => void) => {
    resetForm();
  };

  // Table Options
  const changePage = (pageNumber: number, pageSize: number) => {
    dispatch(setDogFilter({ ...dogFilter, pageNumber: pageNumber, pageSize: pageSize }));
    dispatch(
      getDogsSearch({
        search: { ...dogFilter, pageNumber: pageNumber, pageSize: pageSize },
        intakeStart: dogIntakeStart!,
        intakeEnd: dogIntakeEnd!,
        statusStart: dogStatusStart!,
        statusEnd: dogStatusEnd!,
      })
    );
  };

  const headers = [
    "Actions",
    idOrderDirection === 1 ? "ID ↑" : "ID ↓",
    nameOrderDirection === 1 ? "Dog Name ↑" : "Dog Name ↓",
    akaOrderDirection === 1 ? "AKA ↑" : "AKA ↓",
    breedOrderDirection === 1 ? "Breed ↑" : "Breed ↓",
    fosterOrderDirection === 1 ? "Foster ↑" : "Foster ↓",
    statusOrderDirection === 1 ? "Status ↑" : "Status ↓",
    intakeOrderDirection === 1 ? "Intake Date ↑" : "Intake Date ↓",
    statusChangeOrderDirection === 1 ? "Status Changed ↑" : "Status Changed ↓",
  ];

  if (checkUserRole(loggedInUser!, UserRoles.Admin)) {
    headers.unshift("Print");
  }

  const rows =
    dogSearch?.results.map((dog: DogSimple) => {
      const hasDownload = checkUserRole(loggedInUser!, UserRoles.Admin);

      let rowData = {
        actions: (
          <TableActions
            handleEdit={
              checkUserRole(loggedInUser!, UserRoles.Admin) || checkUserRole(loggedInUser!, UserRoles.Inventory)
                ? async () => {
                    dispatch(setResetDogFilterBool(false));
                    await dispatch(getDogById(dog.id)).then(() => {
                      navigate(`/admin/dog/${dog.id}`, { state: { edit: true } });
                    });
                    dispatch(getDogSimpleById(dog.id));
                  }
                : undefined
            }
            handleDelete={checkUserRole(loggedInUser!, UserRoles.Admin) ? handleDeleteOpen : undefined}
            id={dog.id}
            handleView={async () => {
              dispatch(setResetDogFilterBool(false));
              await dispatch(getDogById(dog.id)).then(() => {
                navigate(`/admin/dog/${dog.id}`);
              });
              dispatch(getDogSimpleById(dog.id));
            }}
            handleCopy={
              checkUserRole(loggedInUser!, UserRoles.Admin) || checkUserRole(loggedInUser!, UserRoles.Inventory)
                ? async () => {
                    dispatch(setResetDogFilterBool(false));
                    const dogClone: any = (await dispatch(getDogById(dog.id))).payload;
                    dispatch(
                      setDog({
                        ...dogClone,
                        id: 0,
                        name: "",
                        alsoKnownAs: "",
                        medicalDog: false,
                        ageGroup: { id: 0, value: "" },
                        microchipNumber: "",
                        status: { id: 1, value: "On Hold - Other" },
                        mainPhotoId: null,
                        photos: [],
                        documents: [],
                        notes: [],
                      })
                    );
                    navigate(`/admin/dog/0`);
                  }
                : undefined
            }
          />
        ),
        id: dog.id,
        dogName: dog.name,
        aka: dog.alsoKnownAs,
        breed: dog.primaryBreed?.value,
        foster: dog.foster?.value,
        status: `${dog.status?.value} ${dog.subStatus?.value ? `- ${dog.subStatus.value}` : ""}`,
        intake: dog.intakeDate && <Moment format="MM/DD/YYYY">{dog.intakeDate!}</Moment>,
        statusChanged: dog.statusUpdated && <Moment format="MM/DD/YYYY">{dog.statusUpdated!}</Moment>,
      };

      return !hasDownload
        ? rowData
        : {
            download: (
              <CheckboxButton
                colorVariant="blue"
                checked={checked[dog.id] || false}
                onChange={handleSelectAll}
                id={dog?.id!.toString()}
              />
            ),
            actions: (
              <TableActions
                handleEdit={
                  checkUserRole(loggedInUser!, UserRoles.Admin) || checkUserRole(loggedInUser!, UserRoles.Inventory)
                    ? async () => {
                        dispatch(setResetDogFilterBool(false));
                        await dispatch(getDogById(dog.id)).then(() => {
                          navigate(`/admin/dog/${dog.id}`, { state: { edit: true } });
                        });
                        dispatch(getDogSimpleById(dog.id));
                      }
                    : undefined
                }
                handleDelete={checkUserRole(loggedInUser!, UserRoles.Admin) ? handleDeleteOpen : undefined}
                id={dog.id}
                handleView={async () => {
                  dispatch(setResetDogFilterBool(false));
                  await dispatch(getDogById(dog.id)).then(() => {
                    navigate(`/admin/dog/${dog.id}`);
                  });
                  dispatch(getDogSimpleById(dog.id));
                }}
                handleCopy={
                  checkUserRole(loggedInUser!, UserRoles.Admin) || checkUserRole(loggedInUser!, UserRoles.Inventory)
                    ? async () => {
                        dispatch(setResetDogFilterBool(false));
                        const dogClone: any = (await dispatch(getDogById(dog.id))).payload;
                        dispatch(
                          setDog({
                            ...dogClone,
                            id: 0,
                            name: "",
                            alsoKnownAs: "",
                            medicalDog: false,
                            ageGroup: { id: 0, value: "" },
                            microchipNumber: "",
                            status: { id: 1, value: "On Hold - Other" },
                            mainPhotoId: null,
                            photos: [],
                            documents: [],
                            notes: [],
                          })
                        );
                        navigate(`/admin/dog/0`);
                      }
                    : undefined
                }
              />
            ),
            id: dog.id,
            dogName: dog.name,
            aka: dog.alsoKnownAs,
            breed: dog.primaryBreed?.value,
            foster: dog.foster?.value,
            status: `${dog.status?.value} ${dog.subStatus?.value ? `- ${dog.subStatus.value}` : ""}`,
            intake: dog.intakeDate && <Moment format="MM/DD/YYYY">{dog.intakeDate!}</Moment>,
            statusChanged: dog.statusUpdated && <Moment format="MM/DD/YYYY">{dog.statusUpdated!}</Moment>,
          };
    }) || [];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", marginTop: -1 }}>
      <Container sx={{ marginBottom: 3 }}>
        <Formik
          enableReinitialize
          initialValues={{
            intakeStart: dogIntakeStart,
            intakeEnd: dogIntakeEnd,
            statusStart: dogStatusStart,
            statusEnd: dogStatusEnd,
            status: dogStatus,
            foster: dogFoster,
            dogSubStatus: dogSubStatus,
          }}
          onSubmit={() => {}}
        >
          {({ values, resetForm }) => {
            return (
              <Form noValidate>
                <Box sx={{ alignItems: "center", display: "flex", justifyContent: "center", marginBottom: "5px" }}>
                  <Container>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                      }}
                    >
                      {checkUserRole(loggedInUser!, UserRoles.Admin) && (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            justifyContent: "flex-start",
                            padding: 1,
                          }}
                        >
                          <Box>
                            <ActionButton
                              text={"Download All Documents"}
                              onClick={downloadAllDocuments}
                              type="button"
                              color="secondary"
                              disabled={downloadStatus === "loading"}
                            />
                          </Box>
                        </Box>
                      )}
                      <Box
                        gap={1}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          justifyContent: "flex-start",
                          padding: 1,
                        }}
                      >
                        <Box>
                          {downloadStatus === "loading" && (
                            <Box display="flex" justifyContent="center" alignItems="center" paddingLeft="10px">
                              <CircularProgress color="primary" size="20px" />
                            </Box>
                          )}
                        </Box>
                        <Box sx={{ display: { sm: "none", xs: "flex" } }}>
                          <ActionButton
                            text={showFilters ? "Hide Filters" : "Show Filters"}
                            onClick={() => setShowFilters(!showFilters)}
                            type="button"
                            color="secondary"
                          />
                        </Box>

                        <Box sx={{ display: { sm: "flex", xs: showFilters ? "flex" : "none" } }}>
                          <ActionButton
                            type="reset"
                            text="Reset Filters"
                            color="success"
                            onClick={async () => {
                              await handleClear();
                              handleReset.bind(resetForm);
                            }}
                          />
                        </Box>
                        {(checkUserRole(loggedInUser!, UserRoles.Admin) ||
                          checkUserRole(loggedInUser!, UserRoles.Inventory)) && (
                          <Box sx={{}}>
                            <ActionButton
                              type="button"
                              text="Add New"
                              color="primary"
                              onClick={() => {
                                dispatch(resetDog());
                                navigate(`/admin/dog/0`);
                              }}
                            />
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Container>
                </Box>
                <Grid
                  container
                  spacing={1}
                  alignItems="center"
                  justifyContent={"center"}
                  sx={{ paddingBottom: 1, display: { sm: "flex", xs: showFilters ? "flex" : "none" } }}
                >
                  <Grid item md={4} xs={12}>
                    <Field
                      name="status"
                      label="Status"
                      options={statuses?.results ? statuses.results : []}
                      component={Inputs.OptionDropdown}
                      searchFunction={getStatusOptions}
                      onSelected={handleStatusFilter}
                      multiple
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Field
                      name="dogSubStatus"
                      label="Sub Status"
                      component={Inputs.Dropdown}
                      options={subStatus?.results! || []}
                      multiple
                      onSelected={handleSubStatusFilter}
                      onChange={handleSubSearch}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Field
                      name="foster"
                      label="Fosters"
                      component={Inputs.OptionDropdown}
                      searchFunction={getUserSearch}
                      onSelected={handleFosterFilter}
                      filterForm={{
                        pageNumber: 1,
                        pageSize: 10,
                        filters: ["role:3"],
                      }}
                      hideLargeResults
                      multiple
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Field
                      name="intakeStart"
                      label="Intake Start"
                      component={Inputs.Datepicker}
                      handleChange={(value: string) => {
                        dispatch(setDogIntakeStart(new Date(value)));
                        if (date instanceof Date && !isNaN(new Date(value).valueOf()!)) {
                          dispatch(
                            getDogsSearch({
                              search: { ...dogFilter },
                              intakeStart: new Date(value),
                              intakeEnd: dogIntakeEnd!,
                              statusStart: dogStatusStart!,
                              statusEnd: dogStatusEnd!,
                            })
                          );
                        }
                      }}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Field
                      name="intakeEnd"
                      label="Intake End"
                      component={Inputs.Datepicker}
                      handleChange={(value: string) => {
                        dispatch(setDogIntakeEnd(new Date(value)));
                        if (date instanceof Date && !isNaN(new Date(value).valueOf()!)) {
                          dispatch(
                            getDogsSearch({
                              search: { ...dogFilter },
                              intakeStart: dogIntakeStart!,
                              intakeEnd: new Date(value)!,
                              statusStart: dogStatusStart!,
                              statusEnd: dogStatusEnd!,
                            })
                          );
                        }
                      }}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Field
                      name="statusStart"
                      label="Status Change Start"
                      component={Inputs.Datepicker}
                      handleChange={(value: string) => {
                        dispatch(setDogStatusStart(new Date(value)));
                        if (date instanceof Date && !isNaN(new Date(value).valueOf()!)) {
                          dispatch(
                            getDogsSearch({
                              search: { ...dogFilter },
                              intakeStart: dogIntakeStart!,
                              intakeEnd: dogIntakeEnd!,
                              statusStart: new Date(value),
                              statusEnd: dogStatusEnd!,
                            })
                          );
                        }
                      }}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Field
                      name="statusEnd"
                      label="Status Change End"
                      component={Inputs.Datepicker}
                      handleChange={(value: string) => {
                        dispatch(setDogStatusEnd(new Date(value)));
                        if (date instanceof Date && !isNaN(new Date(value).valueOf()!)) {
                          dispatch(
                            getDogsSearch({
                              search: { ...dogFilter },
                              intakeStart: dogIntakeStart!,
                              intakeEnd: dogIntakeEnd!,
                              statusStart: dogStatusStart!,
                              statusEnd: new Date(value)!,
                            })
                          );
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Search label="Search" handleChange={updateSearch} value={searchValue} dontSearchOnLoad />
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Container>
      {!!rows.length && status !== "loading" && checkUserRole(loggedInUser!, UserRoles.Admin) && (
        <Box sx={{ alignItems: "center", display: "flex", justifyContent: "center", marginBottom: "5px" }}>
          <Container>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "flex-start",
                padding: 1,
                gap: 2,
              }}
            >
              <Box>
                <CheckboxButton
                  colorVariant="blue"
                  checked={selectAll}
                  onChange={handleSelectAll}
                  id="selectAll"
                  label="Select All"
                />
              </Box>
              <Box>
                <ActionButton
                  text="Print Selected"
                  onClick={printSelectedDocuments}
                  type="button"
                  disabled={
                    downloadStatus === "loading" || Object.values(checked).filter((value) => value === true).length < 2
                  }
                  color="primary"
                />
              </Box>
              <Box>{!!selecting && <CircularProgress size="15px" />}</Box>
            </Box>
          </Container>
        </Box>
      )}
      <Box margin={1}>
        {searchStatus === "loading" && !dogSearch ? (
          <Typography variant="h1" textAlign={"center"}>
            Loading, please wait...
          </Typography>
        ) : (
          <>
            <Typography variant="h2" sx={{ textAlign: "center", marginY: searchStatus === "loading" ? 0 : "29px" }}>
              {searchStatus === "loading" ? `Loading, please wait...` : <></>}
            </Typography>
            {rows.length ? (
              <StyledTable
                headers={headers}
                rows={rows}
                paging={true}
                page={dogFilter.pageNumber}
                rowsPerPage={dogFilter.pageSize}
                totalRows={dogSearch?.totalResults}
                changePage={changePage}
                sortOptions={handleSort}
                sizeVariant="large"
              />
            ) : (
              <Typography textAlign={"center"}>No dogs to display</Typography>
            )}
          </>
        )}
      </Box>
      <Dialog
        open={deleteModal}
        title={`Are you sure you would like to delete ${dog?.name}? This cannot be undone.`}
        warning
      >
        <Box>
          <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
            <ActionButton type="button" text="Cancel" onClick={cancelDelete} />
            <ActionButton
              type="button"
              text={"Delete"}
              disabled={status === "loading" ? true : false}
              color="error"
              onClick={async () => {
                setDeleteModal(false);
                await handleDeleteDog(deleteId);
                setDeleteId(0);
                await dispatch(getDogsSearch({ search: { query: "", pageNumber: 1, pageSize: 10 } }));
              }}
            />
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
};

export default AdminDogTable;
